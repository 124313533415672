/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import React, { ComponentProps } from 'react'
import { Vsb } from 'src/components/Hidden'
import { Colors } from '../../constants/theme'
import BaseButton from '../Buttons/BaseButton'
import Wrapper from './Wrapper'
import { IProps, ISearchProps } from './types'

const StyledButton = styled(BaseButton)`
  &&& {
    border-radius: 10px !important;
    width: 41px !important;
    height: 41px !important;
    min-width: 41px !important;
    min-height: 41px !important;
    padding: 0px;
    background-color: ${Colors.primary};
    svg {
      fill: white !important;
      opacity: 1;
    }
  }
`

const StyledTextField = styled(TextField)<{ greybg?: number }>`
  && {
    height: 41px !important;
    width: 100%;
    background-color: white;
    border-radius: 10px;

    & .MuiFilledInput-root {
      color: ${Colors.black500};
      background-color: white;
      border-radius: 100px;
      padding: 0;
      text-align: center;
    }

    & .MuiInputLabel-root {
      align-self: flex-start;
      font-size: 16px;
      background-color: white;
      padding: 0px 10px;
      top: 12px;
    }

    &.Mui-focused,
    &.MuiFormLabel-filled {
      top: 0px;
      font-size: 14px;
      background-color: transparent;
      display: none;
    }

    &&& .Mui-focused {
      background-color: transparent;
    }

    &&& .MuiFilledInput-root {
      padding-right: 0px;
      background-color: ${({ greybg }) =>
        greybg ? Colors.grey300 : Colors.white500};
    }

    & .MuiFilledInput-root {
      &.MuiInputBase-root {
        font-size: 18px;
        line-height: 18px;
        height: inherit;
      }
    }

    &&& .MuiInputAdornment-positionStart {
      margin-top: 0;
      margin-right: 10px;
    }

    & .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
      margin-left: 4px;
      fill: ${Colors.grey500};
    }

    & input::placeholder {
      color: ${Colors.black400};
      text-align: center;
      font-size: 15px;
      opacity: 1;
    }
  }
`

const buttonProps: ComponentProps<typeof BaseButton> = {
  id: 'input-search',
  'aria-label': 'input-search',
  variant: 'contained',
  rounded: true
}

const Search: React.FC<IProps> = ({
  params,
  searchValue,
  placeholder,
  size,
  handleOnChange,
  handleKeyDown,
  handleOnClick,
  clearSearch,
  greybg
}) => {
  return (
    <StyledTextField
      {...params}
      value={searchValue}
      variant="filled"
      fullWidth
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      size={size}
      greybg={greybg ? 1 : 0}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" sx={{ m: 0, ml: 1 }}>
            <FmdGoodIcon sx={{ m: 0 }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" sx={{ height: '200px' }}>
            {searchValue !== '' ? (
              <IconButton id="close-search" aria-label="Close Search">
                <CloseIcon onClick={clearSearch} />
              </IconButton>
            ) : null}

            <Vsb md lg xl>
              <StyledButton
                {...buttonProps}
                id="search-button-desktop"
                size="large"
                onClick={handleOnClick}
              >
                <SearchIcon sx={{ m: 0 }} />
              </StyledButton>
            </Vsb>
            <Vsb xs sm>
              <StyledButton
                {...buttonProps}
                id="search-button-mobile"
                size="small"
                onClick={handleOnClick}
              >
                <SearchIcon sx={{ m: 0 }} />
              </StyledButton>
            </Vsb>
          </InputAdornment>
        )
      }}
    />
  )
}

const MemoSearchButton: typeof Search = React.memo(Search)
const Wrapped: React.FC<ISearchProps> = (props) => (
  <Wrapper {...props} Input={MemoSearchButton} />
)

export default React.memo(Wrapped)
