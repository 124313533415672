/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useLazyQuery } from '@apollo/client'
import styled from '@emotion/styled'
import RoomIcon from '@mui/icons-material/Room'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import useLogger from 'src/context/LoggerProvider/useLogger'
import useUserLocation from 'src/hooks/useUserLocation'
import {
  CDMX_OPTION,
  MTY_OPTION,
  NEAR_USER,
  NEAR_USER_OPTION
} from '../../constants'
import { Colors, FontWeight } from '../../constants/theme'
import { useDebounce } from '../../hooks/useDebounce'
import {
  IGoogleAutocompleteSuggestion,
  IQuery,
  IQueryGoogleAutocompleteSuggestionsArgs
} from '../../types/graphql'
import { IInternalSearchProps } from './types'

const StyledRoomIcon = styled(RoomIcon)`
  && {
    background: rgba(0, 162, 255, 0.1);
    padding: 2px;
    border-radius: 4px;
    margin-right: 10px;
  }
`

const CustomPaper = (props: any) => {
  return (
    <Paper
      {...props}
      elevation={9}
      unmountOnExit
      sx={{
        borderRadius: 5,
        marginTop: 0.5,
        color: `${Colors.primary}`,
        lineHeight: 10,
        height: '100%',
        backgroundColor: Colors.grey300,
        boxShadow:
          'box-shadow: 0px 12px 34px -12px rgba(23, 94, 133, 0.30); !important'
      }}
    />
  )
}

const AUTOCOMPLETE_SUGGESTIONS = gql`
  query Autocomplete($searchValue: String!) {
    googleAutocompleteSuggestions(searchValue: $searchValue) {
      placeId
      description
    }
  }
`

const Wrapper: React.FC<IInternalSearchProps> = ({
  handleOnSelect,
  hideCloseByOption,
  label = 'Ingrese una dirección',
  placeholder,
  size = 'small',
  hideAddornment,
  isNotAtDetailsPage,
  sx,
  Input,
  dontClearSearch,
  error
}) => {
  const logger = useLogger()
  const { t } = useTranslation('common')
  const { getUserLocation } = useUserLocation()

  const [searchValue, setSearch] = useState<string>('')
  const debouncedValue = useDebounce(searchValue, 500)
  const [checked, setChecked] = useState(false)

  const [suggestions, setSuggestions] = useState<
    IGoogleAutocompleteSuggestion[]
  >([])

  /** Autocomplete Logic */
  const [loadSuggestions, { loading }] = useLazyQuery<
    Pick<IQuery, 'googleAutocompleteSuggestions'>,
    IQueryGoogleAutocompleteSuggestionsArgs
  >(AUTOCOMPLETE_SUGGESTIONS, {
    onCompleted: ({ googleAutocompleteSuggestions }) => {
      setSuggestions(googleAutocompleteSuggestions)
    },
    onError: (error) => {
      logger.error(
        'Error retrieving suggestions',
        { searchValue: debouncedValue },
        error
      )
      setSuggestions([])
      setSearch('')
    }
  })

  useEffect(() => {
    if (debouncedValue) {
      loadSuggestions({ variables: { searchValue: debouncedValue } })
    }
  }, [debouncedValue, loadSuggestions])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value }
    } = event
    setSearch(value)
    if (value !== '') {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }

  const clearSearch = () => {
    if (dontClearSearch) return
    setSearch('')
    setChecked(false)
  }

  const handleOnChangeAutocomplete = async (
    event: SyntheticEvent<Element, Event>,
    value: string | IGoogleAutocompleteSuggestion | null
  ) => {
    if (value && typeof value !== 'string') {
      event.preventDefault()
      handleOnSelect(
        value,
        searchValue,
        value.placeId === NEAR_USER ? await getUserLocation() : undefined
      )
      clearSearch()
    }
  }

  const handleOnClick = () => {
    const fallback = hideCloseByOption
      ? undefined
      : [{ ...NEAR_USER_OPTION, description: t('SEARCH_MANAGER.NEAR_TO_ME') }]
    handleOnSelect(suggestions[0] ?? fallback, searchValue)
    clearSearch()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && suggestions.length > 0) {
      handleOnSelect(suggestions[0], searchValue)
      clearSearch()
    }
  }

  const defaults = hideCloseByOption ? [] : [CDMX_OPTION, MTY_OPTION]
  const nearMeOptions = hideCloseByOption
    ? []
    : [{ ...NEAR_USER_OPTION, description: t('SEARCH_MANAGER.NEAR_TO_ME') }]
  const options =
    typeof window !== 'undefined' &&
    window.navigator.geolocation &&
    searchValue !== ''
      ? [...suggestions, ...nearMeOptions]
      : [...defaults, ...nearMeOptions]

  return (
    <Autocomplete
      freeSolo
      selectOnFocus
      id="custom-autocomplete"
      options={options}
      loading={loading}
      loadingText="Cargando..."
      noOptionsText="No se encontraron resultados"
      value={searchValue}
      onChange={handleOnChangeAutocomplete}
      PaperComponent={CustomPaper}
      getOptionLabel={(opt) =>
        typeof opt === 'string' ? opt : (opt.description ?? searchValue)
      }
      sx={sx}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) =>
        option.description === value.description &&
        option.description === searchValue
      }
      renderInput={(params) => (
        <Input
          params={params}
          searchValue={searchValue}
          label={label}
          size={size}
          hideAddornment={hideAddornment}
          isNotAtDetailsPage={isNotAtDetailsPage}
          loading={loading}
          handleOnChange={handleOnChange}
          handleKeyDown={handleKeyDown}
          handleOnClick={handleOnClick}
          clearSearch={clearSearch}
          placeholder={placeholder}
          error={error}
        />
      )}
      renderOption={(props, { placeId, description }) => (
        <React.Fragment key={placeId}>
          <Collapse in={checked} collapsedSize={40}>
            <Box
              component="li"
              top={0}
              sx={{
                '& > img': { mr: 2, flexShrink: 0, p: 1 },
                mt: 0,
                fontSize: '30px !important'
              }}
              {...props}
            >
              <StyledRoomIcon
                sx={{
                  color: Colors.blue500,
                  width: '28px',
                  height: '28px',
                  mr: 3,
                  ml: -0.5
                }}
                style={{ backgroundColor: 'transparent' }}
              />

              <Typography
                fontSize={17}
                color={
                  placeId === NEAR_USER ? Colors.black500 : Colors.black400
                }
                fontWeight={FontWeight.regular}
                lineHeight="24px"
              >
                {description}
              </Typography>
            </Box>
          </Collapse>
        </React.Fragment>
      )}
    />
  )
}

export default React.memo(Wrapper)
